<template>
<div class="promotion_page">
  <HeaderWrp/>
  <Breadcrumbs>
    <router-link :to="{ name: 'Home', params: {'lang': lang}}">{{ $t('promotion.home') }}</router-link>
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" class="arr">
      <path d="M10.9605 8.14506C11.0423 8.06543 11.0423 7.93409 10.9605 7.85446L5.32138 2.36015C5.2426 2.28339 5.11702 2.28339 5.03824 2.36014C4.95651 2.43977 4.95651 2.57112 5.03824 2.65075L10.6774 8.14506C10.7562 8.22181 10.8818 8.22181 10.9605 8.14506Z" fill="#000001"/>
      <path d="M5.03824 13.6398C4.95651 13.5602 4.95651 13.4289 5.03824 13.3492L10.6791 7.85331C10.7578 7.77656 10.8834 7.77656 10.9622 7.85331C11.0439 7.93294 11.0439 8.06429 10.9622 8.14391L5.32137 13.6398C5.2426 13.7166 5.11701 13.7166 5.03824 13.6398Z" fill="#000001"/>
    </svg>
  
    <router-link :to="{ name: 'promotions', params: {'lang': lang}}">{{ $t('promotion.breadcrumb1') }}</router-link>
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" class="arr">
      <path d="M10.9605 8.14506C11.0423 8.06543 11.0423 7.93409 10.9605 7.85446L5.32138 2.36015C5.2426 2.28339 5.11702 2.28339 5.03824 2.36014C4.95651 2.43977 4.95651 2.57112 5.03824 2.65075L10.6774 8.14506C10.7562 8.22181 10.8818 8.22181 10.9605 8.14506Z" fill="#000001"/>
      <path d="M5.03824 13.6398C4.95651 13.5602 4.95651 13.4289 5.03824 13.3492L10.6791 7.85331C10.7578 7.77656 10.8834 7.77656 10.9622 7.85331C11.0439 7.93294 11.0439 8.06429 10.9622 8.14391L5.32137 13.6398C5.2426 13.7166 5.11701 13.7166 5.03824 13.6398Z" fill="#000001"/>
    </svg>
    <a style="cursor:pointer" class="curr">{{$t('promotion.breadcrumb2')}}</a>
  </Breadcrumbs>
   <div class="container">
    <div class="wrapp">
        <!-- <a class="view" @click="$router.push({name: 'promotions', params: { 'lang': lang }})">
          <img src="/img/success-forward.svg" alt="arrow-img">
          <span>{{$t('promotion.back')}}</span>
        </a> -->
      <h3 class="title">{{responceData.title}}</h3>
      <div class="wrap_preview">
        <img class="preview" :src="responceData.image" alt="">
      </div>
      <div class="wrapp_desc">
        <p class="desc" v-html="responceData.description"></p>
      </div>

    </div>
    
    <div class="media">
      <div class="card" v-for="(card, ind) in wrappResponceMedia" :key="ind" @click="indLightBox = ind">
        <img :src="card.thumb" alt="">
        <!-- <img v-else src="/img/diploma3.jpg" alt=""> -->
      </div>
    </div>

  </div>
  
  <FooterApp/>
  <CoolLightBox 
    :items="wrappResponceMedia" 
    :index="indLightBox"
    :fullScreen="true"
    @close="indLightBox = null">
  </CoolLightBox>
</div>
</template>
<script>
import Breadcrumbs from "@/components/Breadcrumbs";
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
import { mapGetters } from "vuex"
import HeaderWrp from "@/components/HeaderWrp"
import FooterApp from "@/components/FooterApp";

const domain = process.env.VUE_APP_BASE_API;

export default {
  components: {
    Breadcrumbs,
    HeaderWrp,
    FooterApp,
    CoolLightBox
  },
  async beforeMount(){
    this.getBlog();
  },
  data:()=>({
    responceData: {
      promotion_files:[],
      promotion_video_link: []
    },
    lightBox: [],
    indLightBox: null,
  }),
  metaInfo() {
    return {
      title: this.responceData.title_page,
      meta: [
        {
          vmid: 'title',
          name: 'title',
          content: this.responceData.meta_title
        },
        {
          vmid: 'description',
          name: 'description',
          content: this.responceData.meta_description
        }
      ]
    }
  },
  watch:{
    lang(){
      this.getBlog();
    }
  },
  computed: {
    ...mapGetters({ 
      lang: 'languages/getLang'
    }),
    wrappResponceMedia(){
      let array = [];
      this.responceData.promotion_files.forEach((el) => {
        array.push({
          id: el.id,
          title: el.title,
          description: el.description,
          thumb: el.image_preview,
          src: el.file
        });
      });
      this.responceData.promotion_video_link.forEach(el => {
        array.push({
          id: el.id,
          title: el.title,
          description: el.description,
          thumb: el.image_preview,
          src: el.link
        });
      });
      return array;
    },
  },
  methods: {
    checkPdfLink(str){
      return str.substring(str.length - 3).toUpperCase() == "PDF";
    },
    async getBlog(){
      try {
        let request = await fetch(`${domain}/${this.lang}/get-promotion/${this.$route.params.id}/`);
        if(request.ok){
          let result = await request.json();
          this.responceData = result;
        }
        else {
          console.error(request);
          this.$router.push({name: 'Home', params: {lang: this.lang} });
        }
      }
      catch(ex){
        console.error(ex);
      }
    }
  }
}
</script>

<style lang="scss">
@media(min-width: 1200px){
  .promotion_page .wrap_preview{
    width: 60% !important;
  }
  .promotion_page .wrap_preview .preview{
    height: 380px !important;
  }
}
    
  body.night {
    .promotion_page {
      .wrapp {
        .title {
          color: white;
        }
      }
      .wrapp_desc {
        .desc {
          color: white;
        }
      }
    }
  }
  .promotion_page {
    .container {
      padding: 0px 20px;
      @media(max-width: 430px) {
        padding: 0px 16px;
      }
    }
   .wrapp {
     max-width: 1200px;
     margin: auto;
     display: flex;
     flex-direction: column;
    .title {
      font-weight: 600;
      font-size: 40px;
      line-height: 55px;
      color: #000001;
      @media(max-width: 430px){
        font-size: 25px;
        line-height: 30px;
      }
    }
   }
    .title {
      font-size: 20px;
    }
    .wrap_preview {
      height: 600px;
      border-radius: 12px;
      overflow: hidden;
      @media(max-width: 430px){
        height: auto
      }
      .preview {
        width: 100%;
        height: 100%;
        border-radius: 12px;
        object-fit: cover;
        object-position: top;
        @media(min-width: 1200px){
          object-position: top left;
        }
        @media(max-width: 430px){
          object-fit: contain;
          object-position: center;
          height: auto;
        }
      }
    }
    .wrapp_desc {
      margin-top: 25px;
      margin-bottom: 100px;
      .desc {
        font-weight: 500;
        line-height: 27px;
      }
    }
    .media {
      width: 100%;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      margin-bottom: 200px;
      .card {
        width: 350px;
        height: 250px;
        margin: 25px;
        cursor: pointer;
        border-radius: 18px / 28px;
        img {
          width: 100%;
          height: 100%;
          object-position: center;
          object-fit: cover;
          border-radius: 18px / 28px;
        }
      }
    }

    .view {
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      cursor: pointer;
      @media(max-width: 430px){
        display: none;
      }
      img {
        transform: rotate(180deg);
        margin-left: 20px;
        transition: 0.5s;
      }
      span {
        font-weight: 600;
        text-transform: uppercase;
        margin-left: 20px;
        color: #930684;
   
      }
      &:hover {
        img {
          margin-left: 10px;
        }
        span {
          color: #c00cae;
        }
      }
    }


  }

</style>